<template>
  <div>
    <header v-if="!isLoading" class="page-header">
      <PageTitle v-if="user" :item="user" property="email"/>
      <div class="buttons">
        <button v-if="!isLoading && (user && user.id)" class="btn btn-save" @click="requestPasswordReset">
          <span>{{ 'Password reset aanvragen' }}</span>
        </button>
        <button v-if="!isLoading" class="btn btn-save" @click="saveUserProfile()">
          <span>{{ user && user.id ? 'Gebruiker wijzigen' : 'Gebruiker toevoegen' }}</span>
        </button>
      </div>
    </header>

    <div class="main-content">
      <formulate-form v-if="!isLoading" ref="userForm" v-model="user" name="userForm" @failed-validation="countErrors">
        <tabs :options="{ useUrlFragment: false }">
          <tab name="Content">
            <formulate-input :validation="user && user.id ? 'email' : 'required|email'" error-behavior="submit" label="E-mail" name="email" type="email"></formulate-input>
            <formulate-input :options="formatRoles" label="Gebruikersrollen" name="userRoles" type="checkbox" validation="required"></formulate-input>
            <formulate-input label="Wachtwoord" name="password" type="password" validation-name="Wachtwoord"></formulate-input>
            <formulate-input label="Inactief" name="disabled" type="checkbox"></formulate-input>
            <formulate-input v-if="!userId" label="Verstuur password reset e-mail" name="send_password_reset_email" type="checkbox"></formulate-input>
            <div class="formulate-input">
              <label class="formulate-input-label">Dealers</label>
              <v-select id="dealerSelector" v-model="userDealers" :filterable="false" :options="dealers" :value="dealer" label="name" multiple name="dealers" @search="searchDealers">
                <div slot="list-footer" class="pagination">
                  <div :disabled="!hasPrevPage" @click="prevDealers()">Prev</div>
                  <div :disabled="!hasNextPage" @click="nextDealers()">Next</div>
                </div>
              </v-select>
            </div>
          </tab>

          <tab name="Profiel">
            <formulate-input name="profile" type="group">
              <formulate-input label="Voornaam" name="first_name" type="text" validation="required"></formulate-input>
              <formulate-input label="Achternaam" name="last_name" type="text" validation="required"></formulate-input>
              <formulate-input label="Toegang tot calculatie" name="calculator_access" type="checkbox"></formulate-input>
              <formulate-input :options="activeLocalesFront" label="Taal" name="language" type="select"></formulate-input>
            </formulate-input>
          </tab>
        </tabs>
      </formulate-form>
    </div>
  </div>
</template>

<script>
import { Tabs, Tab } from 'vue-tabs-component'
import { ApiService } from '@/services/admin/api.service'
import { notification } from '@/notifications'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import PageTitle from '@/components/admin/PageTitle'

export default {
  name: 'User',
  components: {
    PageTitle,
    Tabs,
    Tab,
    vSelect
  },
  data: function () {
    return {
      isLoading: true,
      user: null,
      userId: this.$route.params.id,
      roles: [],
      offset: 0,
      choice: null,
      limit: 25,
      dealers: [],
      userDealers: [],
      dealer: null,
      search: '',
      timeout: null,
      hasPrevPage: false,
      totalDealers: 0,
      hasNextPage: true
    }
  },
  async mounted () {
    this.isLoading = true

    if (this.checkPermissions(['user.manageRolesAndPermission'])) {
      this.roles = (await ApiService.fetchRoles()).data.roles
    }

    if (this.userId) {
      await this.fetchUser(this.userId)
    }

    this.isLoading = false
  },
  methods: {
    async requestPasswordReset () {
      await ApiService.requestPasswordReset(this.user.email).then(() => {
        notification('Password reset e-mail verzonden!')
      })
    },
    async prevDealers () {
      this.offset--
      this.hasPrevPage = this.offset > 0
      await this.fetchDealers()
    },
    async nextDealers () {
      this.offset++
      this.hasNextPage = this.totalDealers > this.limit
      await this.fetchDealers()
    },
    async searchDealers (search, loading) {
      this.offset = 0
      this.search = search
      await this.fetchDealers()
    },
    async fetchDealers () {
      const response = await ApiService.fetchDealers(this.offset, this.limit, this.search)
      this.dealers = response.data.dealers
      this.totalDealers = response.data.total
    },
    fetchUser: async function (userId) {
      if (!(this.checkPermissions(['user.edit', 'user.manageRolesAndPermission']))) {
        return false
      }

      await ApiService.fetchUser(userId).then(async userRes => {
        if (userRes.status === 200) {
          this.user = userRes.data

          await ApiService.fetchUserDealers(userId).then(userDealersRes => {
            if (userDealersRes.status === 200) {
              this.userDealers = userDealersRes.data
            }
          })
        }
      })
      this.user.userRoles = []
      this.user.roles.forEach(roleRow => this.user.userRoles.push(roleRow.id))
      this.user.profile = [this.user.profile]
    },
    countErrors () {
      const tabs = document.querySelectorAll('.tabs-component-panel')

      for (const tab of tabs) {
        const tabId = tab.id
        const errorsCount = tab.querySelectorAll('.formulate-input-errors').length
        let newErrorsCountSpan = ''

        if (errorsCount > 0) {
          newErrorsCountSpan = '<span class="tab-errors-count">' + errorsCount + '</span>'
        }

        const tabButton = document.querySelector('.tabs-component-tab a[href="#' + tabId + '"]')
        const tabButtonErrorsCountSpan = tabButton.querySelector('.tab-errors-count')

        if (!tabButtonErrorsCountSpan) {
          tabButton.innerHTML = tabButton.innerHTML + newErrorsCountSpan
        } else {
          tabButtonErrorsCountSpan.outerHTML = newErrorsCountSpan
        }
      }
    },
    saveUserProfile: async function () {
      this.$formulate.submit('userForm')

      if (await this.$refs.userForm.hasValidationErrors()) {
        return
      }

      if (this.userDealers.length > 0) {
        this.user.dealers = this.userDealers.map((dealer) => ({ id: dealer.id }))
      }
      this.user.roles = this.user.userRoles.map((role) => ({ id: role }))

      const userData = JSON.parse(JSON.stringify(this.user))
      userData.profile = this.user.profile[0]

      const method = this.user.id ? 'patch' : 'post'
      await ApiService.saveUser(userData)
        .catch((request) => {
          if (request.response.status !== 200) {
            request.response.data.detail.forEach((obj) => {
              notification(this.$t(obj.msg), 'error')
            })
          }
        })
        .then((request) => {
          if (request.status === 200) {
            if (method === 'post') {
              notification('Gebruiker succesvol toegevoegd!')
            } else {
              notification('Gebruiker succesvol aangepast!')
            }

            this.$router.push({ name: 'admin.users' })
          } else {
            if (Array.isArray(request.response.data.detail)) {
              request.response.data.detail.forEach((obj) => {
                notification(this.$t(obj.msg), 'error')
              })
            } else {
              notification(this.$t(request.response.data.detail), 'error')
            }
          }
        })
    }
  },
  computed: {
    paginated () {
      return []
    },
    formatRoles: function () {
      const roles = {}

      this.roles.forEach((roleRow, i) => {
        roles[roleRow.id] = roleRow.name
      })

      return roles
    }
  }
}
</script>
